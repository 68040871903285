import React, { useState, useEffect, useRef } from "react"
import { Article, Figure, Loading } from "../components/layout"
import StackGrid from "react-stack-grid"
import styled from "@emotion/styled"

const miniTitle = title => {
  return title.match(/^.+(?=\s?[,|-]\s[bB]y\s)/g)
}

const replaceEmbeddedHTML = title => {
  return title.replace("&#39;", "'").replace("&qoute;", "'")
}
export const EtsyLink = styled.a`
  color: black;
  text-decoration: none;
  &:hover {
    & h5 {
      text-decoration: underline;
    }
    & img {
      filter: invert(25%) hue-rotate(-90deg);
    }
  }
`

const FeaturedItem = ({ item }) => {
  const description = item.description
  const price = "$" + item.price
  let title = miniTitle(item.title)
  if (!title) {
    title = item.title
  } else {
    title = title[0].trim()
  }
  title = replaceEmbeddedHTML(title)
  // const remaining = item.quantity
  const img = item.images[0].url_570xN
  const url = item.url

  return (
    <section>
      <EtsyLink href={url} title="Buy on Etsy">
        <Figure className="grid-image">
          <img src={img} alt={description} />
          <figcaption>
            <h5
              style={{
                // textAlign: "center",
                padding: 5,
                marginTop: 0,
                marginBottom: 5,
              }}
            >
              {title}
            </h5>
            <label>Price: {price}</label>
            {/* <div className="spaced-row">
            <p>Price: {price}</p>
            <p>
              <a href={url}>Buy on Etsy</a>
            </p>
          </div> */}
            {/* <p>{description}</p> */}
          </figcaption>
        </Figure>
      </EtsyLink>
    </section>
  )
}

const calcColumnWidth = width => {
  if (width <= 1000 && width > 660) {
    return "50%"
  }
  if (width > 1000) {
    return "33%"
  }
  return "100%"
}

const ShopHeader = ({ title }) => {
  return (
    <>
      <hr />
      <h1 style={{ margin: 0 }}>
        {title}{" "}
        <a
          style={{ fontSize: "1.25em", margin: "0px" }}
          className="etsyLink"
          href="https://www.etsy.com/shop/DecaffeinatedDesigns"
        >
          Etsy
        </a>
      </h1>
      <hr />
    </>
  )
}

export const EtsyFeatured = ({ size }) => {
  const [featured, setFeatured] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    async function fetchFeaturedItems() {
      const featured = []
      setLoading(true)
      try {
        const cached = sessionStorage.getItem("featuredEtsy")
        if (cached) {
          setFeatured(() => JSON.parse(cached))
        } else {
          const response = await fetch(
            "/.netlify/functions/fetch-etsy-featured"
          )
          const data = await response.json()
          data.forEach(result => featured.push(result))
          sessionStorage.setItem("featuredEtsy", JSON.stringify(featured))
          setFeatured(() => featured)
        }
      } catch (err) {
        console.warn(`\n could not fetch Featured items`, err)
        return null
      } finally {
        setLoading(false)
      }
    }
    fetchFeaturedItems()
  }, [])
  const grid = useRef(null)
  useEffect(() => {
    requestAnimationFrame(() => {
      if (grid.current) {
        grid.current.updateLayout()
      }
    })
  }, [grid])

  return (
    <Article>
      <ShopHeader title="Featured on" />
      {loading ? (
        <Loading text="... Fetching Featured ..." />
      ) : (
        <>
          {featured.length > 0 ? (
            <StackGrid
              gridRef={r => (grid.current = r)}
              columnWidth={calcColumnWidth(size.width)}
              monitorImagesLoaded={true}
              duration={500}
              gutterWidth={25}
              gutterHeight={20}
              appearDelay={60}
            >
              {featured.map(item => (
                <FeaturedItem key={"" + item.listing_id} item={item} />
              ))}
            </StackGrid>
          ) : (
            <div className="spaced-row">
              <p>Unable to get items from Etsy</p>
            </div>
          )}
        </>
      )}
    </Article>
  )
}

export const Etsy = ({ size }) => {
  const [inventory, setInventory] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    async function fetchEtsy() {
      setLoading(true)
      const featured = []
      try {
        const cached = sessionStorage.getItem("etsyInventory")
        if (cached) {
          setInventory(() => JSON.parse(cached))
        } else {
          const response = await fetch("/.netlify/functions/fetch-etsy-all")
          const data = await response.json()
          data.forEach(result => featured.push(result))
          sessionStorage.setItem("etsyInventory", JSON.stringify(featured))
          setInventory(() => featured)
        }
      } catch (err) {
        console.warn(`\n could not fetch Etsy inventory`, err)
        return null
      } finally {
        setLoading(false)
      }
    }
    fetchEtsy()
  }, [])
  const grid = useRef(null)
  useEffect(() => {
    requestAnimationFrame(() => {
      if (grid.current) {
        grid.current.updateLayout()
      }
    })
  }, [grid])
  return (
    <Article>
      <ShopHeader title="Available at" />
      {loading ? (
        <Loading text="... Fetching Inventory ..." />
      ) : (
        <>
          {inventory.length > 0 ? (
            <StackGrid
              gridRef={r => (grid.current = r)}
              columnWidth={calcColumnWidth(size.width)}
              monitorImagesLoaded={true}
              duration={500}
              gutterWidth={25}
              gutterHeight={20}
              appearDelay={60}
            >
              {inventory.map(item => (
                <FeaturedItem key={"" + item.listing_id} item={item} />
              ))}
            </StackGrid>
          ) : (
            <div className="spaced-row">
              <p>Unable to get items from Etsy</p>
            </div>
          )}
        </>
      )}
      <div className="spaced-row">
        <p>
          Want to see more? Visit my{" "}
          <a href="https://www.etsy.com/shop/DecaffeinatedDesigns">
            Etsy Store
          </a>
          !
        </p>
        <p>
          Looking for something special? <a href="/contact">Contact me</a>!
        </p>
      </div>
    </Article>
  )
}

export default Etsy
